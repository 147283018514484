import { inject, Injectable } from '@angular/core';
import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '../session/app-session.service';

import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { isArray } from 'lodash-es';
import { CheckRoleService } from '@shared/services/check-role.service';

@Injectable()
export class AppRouteGuard {
    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private checkRoleService: CheckRoleService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return false;
        }

        if (!route.data || !route.data['permission']) {
            return true;
        }

        const permissions = route.data['permission'];
        if (permissions) {
            if (isArray(permissions)) {
                const permissionChecked = permissions.some((permission: string) => this._permissionChecker.isGranted(permission));
                if (permissionChecked) {
                    return true;
                }
            } else if (this._permissionChecker.isGranted(route.data['permission'])) {
                return true;
            }
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            return '/account/login';
        }

        // case in which the logged  user is a back office or an admin
        if (this._permissionChecker.isGranted('User.Create') && this._permissionChecker.isGranted('Pages.Professional')) {
            return '/app/professionals';
        }

        // case in which the logged  user is a professional
        if (this.checkRoleService.isProfessional() && this._permissionChecker.isGranted('User.GetMyProfile') && this._permissionChecker.isGranted('Pages.Professional')) {
            return `/app/professionals/${this._sessionService.userId}/details`;
        }

        // case in which the logged  user is a caregiver
        if (this.checkRoleService.isCaregiver() && this._permissionChecker.isGranted('User.GetMyProfile') && this._permissionChecker.isGranted('Pages.Caregiver')) {
            return `/app/caregivers/${this._sessionService.userId}/details`;
        }

        return '/fallback/no-permissions';
    }
}

export const authRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AppRouteGuard).canActivate(route, state);
};
