import { Injectable, Renderer2 } from '@angular/core';
import { RendererFactory2 } from '@node_modules/@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    public active: boolean = true;
    private renderer2: Renderer2;

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer2 = this.rendererFactory.createRenderer(null, null);
    }

    activate() {
        this.active = true;
    }

    deactivate() {
        this.active = false;
    }

    show() {
        if (this.active) {
            abp.ui.setBusy(document.body);
            this.renderer2.addClass(document.body, 'overflow-hidden');
        }
    }

    hide() {
        abp.ui.clearBusy(document.body);
        this.renderer2.removeClass(document.body, 'overflow-hidden');
    }
}
