import { Injectable } from '@angular/core';
import { RoleNames } from '@shared/enums/role-names';
import { AppSessionService } from '@shared/session/app-session.service';

@Injectable({
    providedIn: 'root',
})
export class CheckRoleService {
    constructor(private sessionService: AppSessionService) {}

    isProfessional(): boolean {
        return !!this.sessionService.user.roles.find((role) => role === RoleNames.professional.toString());
    }
    isCaregiver(): boolean {
        return !!this.sessionService.user.roles.find((role) => role === RoleNames.caregiver.toString());
    }
}
