/* eslint-disable @typescript-eslint/naming-convention */

export enum PermissionConsts {
    //region Manager
    Request_Manager = 'Request.Manager',
    WorkShift_Manager = 'WorkShift.Manager',

    // region Pages
    Pages_Tenants = 'Pages.Tenants',
    Pages_Roles = 'Pages.Roles',
    Pages_Users = 'Pages.Users',
    Pages_Users_Activation = 'Pages.Users.Activation',
    Pages_Professional = 'Pages.Professional',
    Pages_Caregiver = 'Pages.Caregiver',
    Pages_Request = 'Pages.Request',
    Pages_RequestNote = 'Pages.RequestNote',
    Pages_WorkShift = 'Pages.WorkShift',
    Pages_Chronology = 'Pages.Chronology',

    // region Users
    User_RequestEmailConfirmation = 'User.RequestEmailConfirmation',
    User_Details = 'User.Details',
    User_Create = 'User.Create',
    User_Edit = 'User.Edit',
    User_Delete = 'User.Delete',
    User_ForceResetPassword = 'User.ForceResetPassword',
    User_Deactivate = 'User.Deactivate',
    User_UnlockLogin = 'User.UnlockLogin',

    // region myProfile
    User_GetMyProfile = 'User.GetMyProfile',
    User_UpdateMyProfile = 'User.UpdateMyProfile',

    // region Files
    File_List = 'File.List',
    File_Detail = 'File.Detail',
    File_Create = 'File.Create',
    File_Update = 'File.Update',
    File_Delete = 'File.Delete',

    // region Request
    Request_Create = 'Request.Create',
    Request_Update = 'Request.Update',
    Request_Get = 'Request.Get',
    Request_GetAll = 'Request.GetAll',
    Request_Delete = 'Request.Delete',
    Request_Archive = 'Request.Archive',

    // region RequestNote
    RequestNote_Create = 'RequestNote.Create',
    RequestNote_Delete = 'RequestNote.Delete',
    RequestNote_GetAll = 'RequestNote.GetAll',

    // region AvailabilityWorkShift
    AvailabilityWorkShift_Create = 'AvailabilityWorkShift.Create',
    AvailabilityWorkShift_GetAll = 'AvailabilityWorkShift.GetAll',
    AvailabilityWorkShift_Update = 'AvailabilityWorkShift.Update',
    AvailabilityWorkShift_Delete = 'AvailabilityWorkShift.Delete',

    // region DataInvoice
    DataInvoice_Create = 'DataInvoice.Create',
    DataInvoice_Detail = 'DataInvoice.Detail',
    DataInvoice_Update = 'DataInvoice.Update',
    DataInvoice_Delete = 'DataInvoice.Delete',

    // workShift
    WorkShift_GetAll = 'WorkShift.GetAll',
    WorkShift_Create = 'WorkShift.Create',
    WorkShift_Update = 'WorkShift.Update',
    WorkShift_Get = 'WorkShift.Get',
    WorkShift_Delete = 'WorkShift.Delete',

    // Chronology_GetAll
    Chronology_GetAll = 'Chronology.GetAll',

    // region Education
    Education_Create = 'Education.Create',
    Education_Delete = 'Education.Delete',
    Education_Detail = 'Education.Detail',
    Education_List = 'Education.List',

    // region WorkExperience
    WorkExperience_Create = 'WorkExperience.Create',
    WorkExperience_Delete = 'WorkExperience.Delete',
    WorkExperience_Detail = 'WorkExperience.Detail',
    WorkExperience_List = 'WorkExperience.List',
}
